import React from 'react'
import Layout from '../components/layout/Layout'

export default function NotFoundPage() {
  return (
    <Layout>
      <h4>404: Not Found</h4>
    </Layout>
  )
}
